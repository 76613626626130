import Http from "./Http";

export default {
  async getFixtures() {
    return await Http.get(`/team/fixtures`);
  },
  async getMatch(id) {
    return await Http.get(`/team/match/${id}`);
  },
  async getMatchExtended(id) {
    return await Http.get(`/team/match/${id}/extended`);
  },
  async createRoster(id) {
    return await Http.post(`/team/match/${id}/lineups`);
  },
  async getLineups(id) {
    return await Http.get(`/team/match/${id}/lineups`);
  },
  async removeFixtures(id) {
    return await Http.delete(`/team/match/${id}`);
  },
  async addEventSubstitution(id, player_id, event, team, second_player_id) {
    return await Http.post(`/team/match/${id}/event`, {
      event: event,
      player_id: player_id,
      home_away: team ? -1 : 1, //turning 0,1 to -1 1
      second_player_id: second_player_id,
    });
  },
  async addEvent(id, player_id, event, team) {
    return await Http.post(`/team/match/${id}/event`, {
      event: event,
      player_id: player_id,
      home_away: team ? -1 : 1, //turning 0,1 to -1 1
    });
  },
  async changeStatus(id, status) {
    return await Http.post(`/team/match/${id}/changeStatus`, {
      status: status,
    });
  },
  async getTeams() {
    return await Http.get(`/team/teams`);
  },
  async addMatch(payload) {
    return await Http.post(`/team/match`, payload);
  },
  async getEvents(id) {
    return await Http.get(`/team/match/${id}/events`);
  },
  async addMOTM(id, player_id){
    return await Http.post(`/games/motm/match/${id}/end`, {
      player_id: player_id,
    });
  }
};
